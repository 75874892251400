import { createApp } from 'vue'

// 有坑：样式的引入需要放在前面
import ElementPlus from 'element-plus'
import zhCN from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
import './index.scss' // 自定义全局样式
import installIcons from './plugins/icons' // 引入图标注册函数
import installDirectives from './plugins/directives' // 引入自定义指令

import App from './App.vue'
import router from './router'
import store from './store'

const app = createApp(App)
installIcons(app)
installDirectives(app)
app.use(ElementPlus, { locale: zhCN }).use(store).use(router)
app.mount('#app')
