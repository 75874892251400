import AppConst from '@/constants/app_const'
import store from '@/store'
import Storage from '@/utils/storage'

export default {
  namespaced: true,

  state: {
    user: Storage.get(AppConst.USER) == null ? {} : Storage.get(AppConst.USER),
    loggingOut: false,
    // 用户授权相关
    hasAuthorizations: !(Storage.get(AppConst.AUTHORIZATIONS) == null),
    gettingAuthorizations: false,
    authorizations: Storage.get(AppConst.AUTHORIZATIONS) == null ? {} : Storage.get(AppConst.AUTHORIZATIONS),
    // 用户信息相关
    hasProfile: !(Storage.get(AppConst.PROFILE) == null),
    gettingProfile: false,
    profile: Storage.get(AppConst.PROFILE) == null ? {} : Storage.get(AppConst.PROFILE)
  },

  mutations: {
    // 设置user值
    setUser(state, user) {
      state.user = user
    },
    // 设置loggingOut值
    setLoggingOut(state, value) {
      state.loggingOut = value
    },
    // 设置hasAuthorizations值
    setHasAuthorizations(state, value) {
      state.hasAuthorizations = value
    },
    // 设置gettingAuthorizations值
    setGettingAuthorizations(state, value) {
      state.gettingAuthorizations = value
    },
    // 存储授权数据
    setAuthorizations(state, authorizations) {
      state.authorizations = authorizations
    },
    // 设置hasProfile值
    setHasProfile(state, value) {
      state.hasProfile = value
    },
    // 设置gettingProfile值
    setGettingProfile(state, value) {
      state.gettingProfile = value
    },
    // 存储个人信息
    setProfile(state, profile) {
      state.profile = profile
    },
    // 初始化state状态
    initState(state) {
      state.user = {}

      state.hasAuthorizations = false
      state.gettingAuthorizations = false
      state.authorizations = {}

      state.hasProfile = false
      state.gettingProfile = false
      state.profile = {}
    }
  },

  actions: {
    /**
     * 初始化前端系统状态
     */
    init: (context) => {
      console.groupCollapsed('init: 初始化前端系统状态')

      console.log('清理state状态')
      store.commit('app/initState')
      store.commit('layout/initState')

      console.log('清理浏览器缓存')
      const username = Storage.get(AppConst.USERNAME)
      const phone = Storage.get(AppConst.PHONE)
      const email = Storage.get(AppConst.EMAIL)
      Storage.clear()
      if (username != null) Storage.set(AppConst.USERNAME, username)
      if (phone != null) Storage.set(AppConst.PHONE, phone)
      if (email != null) Storage.set(AppConst.EMAIL, email)

      console.groupEnd()
    }
  }
}
