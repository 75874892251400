import Storage from '@/utils/storage'
import RouteConst from '@/constants/route_const'

const SIDEBAR_EXPAND = 'sidebarExpand'
const TAG_LIST = 'tagList'

export default {
  namespaced: true,

  state: {
    sidebarExpand: Storage.get(SIDEBAR_EXPAND) == null ? true : Storage.get(SIDEBAR_EXPAND),
    tagList: Storage.get(TAG_LIST) || []
  },

  mutations: {
    // 切换菜单栏伸缩状态
    changeSidebarExpand(state) {
      state.sidebarExpand = !state.sidebarExpand
    },

    // 添加新的页面标签数据到缓存中的页面标签列表中
    addTag(state, tag) {
      const isFind = state.tagList.find((item) => {
        return item.path === tag.path
      })
      if (!isFind) {
        state.tagList.push(tag)
      }
    },

    // 删除一个或多个标签
    removeTags(state, payload) {
      const mode = payload.mode
      const index = payload.index
      if (mode === 'index') {
        state.tagList.splice(index, 1)
      } else if (mode === 'all') {
        let tagIndex = -1
        for (let i = 0; i < state.tagList.length; i++) {
          if (state.tagList[i].fullPath === RouteConst.INDEX) {
            tagIndex = i
            break
          }
        }
        if (tagIndex === -1) {
          state.tagList = []
        } else {
          state.tagList.splice(tagIndex + 1, state.tagList.length - tagIndex + 1)
          state.tagList.splice(0, tagIndex)
        }
      } else if (mode === 'other') {
        state.tagList.splice(index + 1, state.tagList.length - index + 1)
        state.tagList.splice(0, index)
      } else if (mode === 'right') {
        state.tagList.splice(index + 1, state.tagList.length - index + 1)
      }
    },

    // 初始化state状态
    initState(state) {
      state.sidebarExpand = true
      state.tagList = []
    }
  },

  actions: {
    /**
     * 切换菜单栏伸缩状态
     */
    changeSidebarExpand(context) {
      context.commit('changeSidebarExpand')
      Storage.set(SIDEBAR_EXPAND, context.state.sidebarExpand) // 记住菜单展开/收起状态
    },

    /**
     * 添加新的页面标签数据到缓存中的页面标签列表中
     * @param {*} context
     * @param {fullPath, meta, name, params, path, query, title} tag
     */
    addTag(context, tag) {
      context.commit('addTag', tag)
      Storage.set(TAG_LIST, context.state.tagList)
    },

    /**
     * 删除一个或多个页面标签
     * @param {*} context
     * @param {mode: 'other'||'right'||'index', index} payload 参数对象，包含mode和index（可选）属性
     */
    removeTags(context, payload) {
      context.commit('removeTags', payload)
      Storage.set(TAG_LIST, context.state.tagList)
    }
  }
}
