export default {
  IAM_PERMISSION_ADD: 'iam:permission:add',
  IAM_PERMISSION_DELETE: 'iam:permission:delete',
  IAM_PERMISSION_EDIT: 'iam:permission:edit',
  IAM_PERMISSION_QUERY: 'iam:permission:query',

  IAM_ROLE_ADD: 'iam:role:add',
  IAM_ROLE_DELETE: 'iam:role:delete',
  IAM_ROLE_EDIT: 'iam:role:edit',
  IAM_ROLE_QUERY: 'iam:role:query',

  IAM_USER_ADD: 'iam:user:add',
  IAM_USER_DELETE: 'iam:user:delete',
  IAM_USER_EDIT: 'iam:user:edit',
  IAM_USER_QUERY: 'iam:user:query',

  UPDATE_GRANTS: 'updateGrants',
  UPDATE_BINDS: 'updateBinds'
}
