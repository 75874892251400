export default {
  PORTAL: '/portal',
  LOGIN: '/portal/login',
  REGISTER: '/portal/register',

  INDEX: '/',
  INDEX_REDIRECT: '/homepage',
  HOMEPAGE: '/homepage',
  NOT_AUTHORIZED: '/401',
  NOT_AUTHORIZED_EMBEDDED: '/401/embedded',
  NOT_FOUND: '/404',
  NOT_CONNECTED: '/500',

  LEDGER: '/ledger',
  LEDGER_REDIRECT: '/ledger/dashboard',
  LEDGER_DASHBOARD: '/ledger/dashboard',
  LEDGER_DETAIL: '/ledger/detail',
  LEDGER_BUDGET: '/ledger/budget',
  LEDGER_ASSETS: '/ledger/assets',
  LEDGER_SETTING: '/ledger/setting',

  IAM: '/iam',
  IAM_REDIRECT: '/iam/user-manage',
  USER_MANAGE: '/iam/user-manage',
  ROLE_MANAGE: '/iam/role-manage',
  PERMISSION_MANAGE: '/iam/permission-manage',

  USER_SETTING: '/user-setting'
}
