import { ElMessage } from 'element-plus'
import AppConst from '@/constants/app_const'
import store from '@/store'
import Storage from '@/utils/storage'
import API from '@/api'

/**
 * 获取用户授权数据
 */
const getAuthorizations = async () => {
  console.groupCollapsed('getAuthorizations: 获取用户授权数据')

  store.commit('app/setGettingAuthorizations', true)
  await API.iam.user
    .getAuthorizations()
    .then((res) => {
      if (res && res.success) {
        console.log('成功获取用户授权数据')
        const { authorizations } = res.data
        Storage.set(AppConst.AUTHORIZATIONS, authorizations)
        store.commit('app/setAuthorizations', authorizations)
        store.commit('app/setHasAuthorizations', true)
      } else {
        if (res && res.message != null) {
          console.log('获取用户授权数据失败：', res.message)
          ElMessage.error(res.message)
        } else {
          console.log('获取用户授权数据失败')
          ElMessage.error('获取用户授权数据失败')
        }
      }
    })
    .catch((error) => {
      console.log(error)
      ElMessage.error(error.message)
    })
  store.commit('app/setGettingAuthorizations', false)

  console.groupEnd()
}

/**
 * 刷新用户授权数据
 */
const updateAuthorizations = async () => {
  console.groupCollapsed('updateAuthorizations: 刷新用户授权数据')
  let result = false

  store.commit('app/setGettingAuthorizations', true)
  await API.iam.user
    .updateAuthorizations()
    .then((res) => {
      if (res && res.success) {
        console.log('成功获取用户授权数据')
        const { authorizations } = res.data
        Storage.set(AppConst.AUTHORIZATIONS, authorizations)
        store.commit('app/setAuthorizations', authorizations)
        store.commit('app/setHasAuthorizations', true)
        result = true
      } else {
        if (res && res.message != null) {
          console.log('获取用户授权数据失败：', res.message)
        } else {
          console.log('获取用户授权数据失败')
        }
      }
    })
    .catch((error) => {
      console.log(error)
      ElMessage.error(error.message)
    })
  store.commit('app/setGettingAuthorizations', false)

  console.groupEnd()
  return result
}

export default {
  getAuthorizations,
  updateAuthorizations
}
