import { ElMessage } from 'element-plus'
import AppConst from '@/constants/app_const'
import store from '@/store'
import Storage from '@/utils/storage'
import API from '@/api'

/**
 * 获取用户个人信息
 */
const getProfile = async (userId) => {
  console.groupCollapsed('getProfile: 获取用户个人信息')

  store.commit('app/setGettingProfile', true)
  await API.content.profile
    .getProfile(userId)
    .then((res) => {
      if (res && res.success) {
        console.log('成功获取用户个人信息')
        const { profile } = res.data
        Storage.set(AppConst.PROFILE, profile)
        store.commit('app/setProfile', profile)
        store.commit('app/setHasProfile', true)
      } else {
        if (res && res.message != null) {
          console.log('获取用户个人信息失败：', res.message)
          ElMessage.error(res.message)
        } else {
          console.log('获取用户个人信息失败')
          ElMessage.error('获取用户个人信息失败')
        }
      }
    })
    .catch((error) => {
      console.log(error)
      ElMessage.error(error.message)
    })
  store.commit('app/setGettingProfile', false)

  console.groupEnd()
}

export default {
  getProfile
}
