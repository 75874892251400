<template>
  <div class="page-wrapper" :class="[store.state.layout.sidebarExpand ? 'sidebar-expand' : 'sidebar-hidden']">
    <Sidebar />
    <div class="right-wrapper">
      <div class="fixed-header">
        <Navbar />
        <Tagbar />
      </div>
      <Content />
    </div>
  </div>
</template>

<script setup>
import { watch } from 'vue'
import { useStore } from 'vuex'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import Tagbar from './Tagbar'
import Content from './Content'
import AuthorizationService from '@/services/authorization_service'
import ProfileService from '@/services/profile_service'

const store = useStore()

// ----- 授权数据 -----
watch(
  () => store.state.app.hasAuthorizations,
  async (value, oldValue) => {
    if (!value && !store.state.app.gettingAuthorizations && !store.state.app.loggingOut) {
      await AuthorizationService.getAuthorizations()
    }
  },
  { immediate: true }
)

// ----- 用户个人信息 -----
watch(
  () => store.state.app.hasProfile,
  async (value, oldValue) => {
    if (!value && !store.state.app.gettingProfile) {
      await ProfileService.getProfile(store.state.app.user.id)
    }
  },
  { immediate: true }
)
</script>

<style lang="scss" scoped>
@import './index.scss';

.page-wrapper {
  width: 100vw;
  height: 100vh;
  background-color: #F2F3F5;

  .right-wrapper {
    height: 100%;
    margin-left: $--sidebar-width;
    transition: margin-left #{$--sidebar-transition-duration};

    .fixed-header {
      position: fixed;
      top: 0;
      right: 0;
      z-index: 1001;
      width: calc(100% - #{$--sidebar-width});
      transition: width #{$--sidebar-transition-duration};
    }
  }
}

.sidebar-hidden {
  .right-wrapper {
    margin-left: $--sidebar-width-hidden !important;

    .fixed-header {
      width: calc(100% - #{$--sidebar-width-hidden});
    }
  }
}
</style>
