import { createStore } from 'vuex'
import app from './modules/app'
import layout from './modules/layout'

export default createStore({
  modules: {
    app,
    layout
  }
})
